const getDescPage = (desc: string, num: number | string) => {
	if (num !== undefined && num !== null && num !== '') {
		const lastDotIndex = desc.lastIndexOf('.');
		if (lastDotIndex !== -1) {
			return desc.slice(0, lastDotIndex) + num + desc.slice(lastDotIndex);
		}
	}
	return desc;
};

export default getDescPage;

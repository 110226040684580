import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { NextSeoProps } from 'next-seo/lib/types';

// import g from 'src/constants/global';
import METADATA from 'src/constants/metadata';
import { IArticleByGroupDetail } from 'src/interface/ArticleByGroup';
import { authorName } from 'src/utils/func';
import getDescPage from 'src/utils/func/get-desc-page';
import getPageMeta from 'src/utils/func/get-page-meta';
// import layoutMobile from 'src/utils/layout-mobile';

interface HeadShareProps extends NextSeoProps {
	hideSuffix?: boolean;
	pageItem?: IArticleByGroupDetail;
}

// const isShouldNoIndexAndFollow = (router: NextRouter) =>
// g.WEB_ENV !== 'production' || layoutMobile(router);

const HeadShare = ({
	additionalMetaTags = [],
	openGraph = {},
	canonical: canonicalPath,
	...props
}: HeadShareProps) => {
	const r = useRouter();
	const page = getPageMeta(r);

	let isArticle = false;
	const articlePathsRegexArray: RegExp[] = [
		/^\/gaya-hidup(?:\/[^\/]+)*/i,
		/^\/ibu-anak(?:\/[^\/]+)*/i,
		/^\/info-sehat(?:\/[^\/]+)*/i,
		/^\/obat(?:\/[^\/]+)*/i,
		/^\/penyakit(?:\/[^\/]+)*/i,
		/^\/psikologi(?:\/[^\/]+)*/i,
	];
	for (const regex of articlePathsRegexArray) {
		if (regex.test(r.asPath) === true && props.pageItem !== undefined) {
			isArticle = true;
		}
	}

	const isKlikStore = r.asPath.substring(0, 10) === '/kalstore';

	const title = props.title || (isKlikStore ? METADATA.APP_NAME_MP : METADATA.APP_NAME);
	const description =
		props.description || (isKlikStore ? METADATA.APP_DESCRIPTION_MP : METADATA.APP_DESCRIPTION);
	const defaultImage = METADATA.IMG_SHARE;

	const hideSuffix = props.hideSuffix ? '' : ' - ' + METADATA.APP_NAME;

	const itemNoIndex = props.noindex ? 'noindex' : 'index';
	const itemNoFollow = props.nofollow ? 'nofollow' : 'follow';
	// const itemMetaIndex = isShouldNoIndexAndFollow(r)
	// ? 'noindex'
	// : props?.pageItem?.meta_robots_index || itemNoIndex;
	// const itemMetaFollow = isShouldNoIndexAndFollow(r)
	// ? 'nofollow'
	// : props?.pageItem?.meta_robots_follow || itemNoFollow;

	const itemMetaIndex = itemNoIndex;
	const itemMetaFollow = itemNoFollow;

	const itemTitle = (props?.pageItem?.meta_title || title) + page;
	const itemDescription = getDescPage(props?.pageItem?.meta_description || description, page);
	const itemImage =
		props?.pageItem?.media_images?.[0]?.original ||
		props?.pageItem?.thumbnail_url ||
		props?.pageItem?.share_image ||
		props?.pageItem ||
		defaultImage;

	return (
		<>
			{!!props.pageItem ? (
				<NextSeo
					noindex={itemMetaIndex === 'noindex'} // TODO: not return index (next feature)
					nofollow={itemMetaFollow === 'nofollow'} // TODO: not return follow (next feature)
					{...props}
					title={itemTitle}
					description={itemDescription}
					openGraph={{
						title: itemTitle,
						description: itemDescription,
						images: [{ url: itemImage, alt: `Gambar ${itemTitle}` }],
						...openGraph,
					}}
					additionalMetaTags={[
						{
							name: 'twitter:title',
							content: itemTitle,
						},
						{
							name: 'twitter:description',
							content: itemDescription,
						},
						{
							name: 'twitter:image',
							content: itemImage,
						},
						{
							name: 'twitter:robots',
							content: `${itemMetaIndex},${itemMetaFollow}`,
						},
						{
							name: 'og:robots',
							content: `${itemMetaIndex},${itemMetaFollow}`,
						},
						...additionalMetaTags,
					]}
				/>
			) : (
				<NextSeo
					noindex={itemMetaIndex === 'noindex'}
					nofollow={itemMetaFollow === 'nofollow'}
					{...props}
					title={title + page}
					description={getDescPage(description, page)}
					openGraph={{
						title: title + page,
						description: getDescPage(description, page),
						images: [{ url: itemImage, alt: `Gambar ${itemTitle}` }],
						...openGraph,
					}}
					additionalMetaTags={[
						{
							name: 'twitter:title',
							content: title + page,
						},
						{
							name: 'twitter:description',
							content: getDescPage(description, page),
						},
						{
							name: 'twitter:image',
							content: itemImage,
						},
						...additionalMetaTags,
					]}
				/>
			)}

			<Head>
				<title>{title + page + hideSuffix}</title>

				{/* NOTE: unique case, we can't set this on component */}
				<meta name="title" content={title + page + hideSuffix} />

				{isArticle && (
					<>
						<meta name="author" content={authorName(props.pageItem)} />
						<meta name="publisher" content="KlikDokter" />
					</>
				)}

				<link
					rel="alternate"
					media="only screen and (max-width: 640px)"
					href={METADATA.WEB_URL + (canonicalPath ? canonicalPath : r.asPath)}
				/>
				<link
					rel="alternate"
					media="handheld"
					href={METADATA.WEB_URL + (canonicalPath ? canonicalPath : r.asPath)}
				/>
			</Head>
		</>
	);
};

export default HeadShare;
